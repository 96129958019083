/** @format */

import { createBrowserRouter, Outlet } from "react-router-dom";
import Layout from "./components/Common/Layout/Layout";
import ForgetPass from "./screens/auth/forget-password/page";
import GoogleSignUpVerify from "./screens/auth/google-auth-verify/page";
import GoogleSignUp from "./screens/auth/google-auth/page";
import ResetPassword from "./screens/auth/reset-password/page";
import SignIn from "./screens/auth/sign-in/page";
import SignUp from "./screens/auth/sign-up/page";
import Balance from "./screens/balance-list/page";
import Category from "./screens/category";
import ChargeWallet from "./screens/charge-wallet/page";
import Fav from "./screens/fav";

import App from "./App";
import OrderDetails from "./components/Orders/OrderDetails/OrderDetails";

import Protected from "./components/Common/Protected/Protected";
import EmployeeMutation from "./screens/Employees/EmployeeMutation/EmployeeMutation";
import EmployeeRoleMutation from "./screens/Employees/EmployeeRoleMutation/EmployeeRoleMutation";
import Employees from "./screens/Employees/page";
import Home from "./screens/home/indx";
import Invoice from "./screens/invoice/page";
import Orders from "./screens/orders/page";
import Product from "./screens/product";
import Products from "./screens/products/page";
import EditProfile from "./screens/profile/edit/page";
import ViewProfile from "./screens/profile/view/page";
import Reports from "./screens/Reports/page";
import Store from "./screens/store";
import Support from "./screens/support";
import AddTicket from "./screens/support/add-ticket";
import UnAuth from "./screens/Unautherized";

const router = createBrowserRouter([
  {
    path: `/:locale?`,
    element: (
      <App>
        <Layout>
          <Outlet />
        </Layout>
      </App>
    ),
    children: [
      //auth
      { path: "auth/forget-password", element: <ForgetPass /> },
      { path: "auth/reset-password", element: <ResetPassword /> },
      { path: "auth/sign-in", element: <SignIn /> },
      { path: "auth/sign-up", element: <SignUp /> },
      { path: "auth/google-sign-up", element: <GoogleSignUp /> },
      { path: "auth/google-sign-up-verify", element: <GoogleSignUpVerify /> },
      {
        path: "",
        index: true,
        element: (
          <Protected permissions={["HomeSection"]}>
            <Home />
          </Protected>
        ),
      },

      //wallet
      {
        path: "products",
        element: (
          <Protected permissions={["ProductsList"]}>
            <Products />
          </Protected>
        ),
      },
      {
        path: "balance-list",
        element: (
          <Protected permissions={["WalletBalanceList"]}>
            <Balance />
          </Protected>
        ),
      },
      {
        path: "charge-wallet",
        element: (
          <Protected permissions={["WalletAddBalance"]}>
            <ChargeWallet />
          </Protected>
        ),
      },
      //store
      {
        path: "store",
        element: (
          <Protected permissions={["Store"]}>
            <Store />
          </Protected>
        ),
      },
      {
        path: "fav",
        element: (
          <Protected permissions={["FavoritesProductsList"]}>
            <Fav />
          </Protected>
        ),
      },
      {
        path: "store/brand/:brandId/sub-category/:categoryId",
        element: (
          <Protected permissions={["Store"]}>
            <Category />
          </Protected>
        ),
      },
      {
        path: "store/brand/:brandId/category/:categoryId",
        element: (
          <Protected permissions={["Store"]}>
            <Product />
          </Protected>
        ),
      },

      //support
      {
        path: "support",
        element: (
          <Protected permissions={["TicketsList"]}>
            <Support />
          </Protected>
        ),
      },
      {
        path: "support/add-ticket",
        element: (
          <Protected permissions={["AddTicket"]}>
            <AddTicket />
          </Protected>
        ),
      },
      //settings
      //->profile
      {
        path: "settings/profile/view",
        element: (
          <Protected permissions={["SettingViewProfile"]}>
            <ViewProfile />
          </Protected>
        ),
      },
      {
        path: "settings/profile/edit",
        element: (
          <Protected permissions={["SettingUpdateProfile"]}>
            <EditProfile />
          </Protected>
        ),
      },

      //->Employee
      {
        path: "settings/employees",
        element: (
          <Protected permissions={["SettingAddAdminList"]}>
            <Employees />
          </Protected>
        ),
      },
      {
        path: "settings/Employees/edit/:id",
        element: (
          <Protected permissions={["SettingEditAdminList"]}>
            <EmployeeMutation />
          </Protected>
        ),
      },
      {
        path: "settings/Employees/add",
        element: (
          <Protected permissions={["SettingAddAdminList"]}>
            <EmployeeMutation />
          </Protected>
        ),
      },
      //->Role
      {
        path: "settings/Employees/add-role",
        element: (
          <Protected permissions={["SettingAddRole"]}>
            <EmployeeRoleMutation />
          </Protected>
        ),
      },
      {
        path: "settings/Employees/edit-role/:id",
        element: (
          <Protected permissions={["SettingEditRole"]}>
            <EmployeeRoleMutation />
          </Protected>
        ),
      },

      //orders
      {
        path: "orders",
        element: (
          <Protected permissions={["OrdersList"]}>
            <Orders />
          </Protected>
        ),
      },
      {
        path: "orders/:orderId",
        element: (
          <Protected permissions={["ViewOrder"]}>
            <OrderDetails />
          </Protected>
        ),
      },
      {
        path: "invoice/:orderId",
        element: (
          <Protected permissions={["ViewOrder"]}>
            <Invoice />
          </Protected>
        ),
      },

      //reports
      {
        path: "reports",
        element: (
          <Protected permissions={["Reports"]}>
            <Reports />
          </Protected>
        ),
      },
    ],
  },
  { path: "unauthorized", element: <UnAuth /> },
]);

export default router;
