/** @format */

import Lottie from "lottie-react";
import Logo from "../../../assets/images/logo-onLight.png";
import styles from "./AuthLayout.module.scss";
type TProps = {
  children: React.ReactNode;
  lottie: object;
};
function AuthLayout({ children, lottie }: TProps) {
  return (
    <div className={styles.authLayout}>
      <div className={styles.content}>
        <div className={styles.authBg}>
          <img alt="logo" src={Logo} />
          <div className={styles.gif}>
            <Lottie
              animationData={lottie}
              style={{ width: "350px", height: "350px" }}
              loop={true}
            />
          </div>
        </div>
        <div className={styles.form}>{children}</div>
      </div>
    </div>
  );
}
export default AuthLayout;
