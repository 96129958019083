/** @format */

import { AxiosResponse } from "axios";
import { TResponse } from "../types/api";
import { THome, TProduct } from "../types/products";
import api from "./api";

export const productService = {
  getProducts: async (): Promise<AxiosResponse<TResponse<TProduct[]>>> =>
    api.get("/products"),

  searchProducts: async (
    search: string
  ): Promise<AxiosResponse<TResponse<TProduct[]>>> =>
    api.get("/search-products?name=" + search),
  getHome: async (): Promise<AxiosResponse<TResponse<THome>>> =>
    api.get("/dashboard"),
};
