export const orderStatus = {
  completed: {
    color: "green",
    label: "مكتمل الدفع",
  },
  waiting: {
    color: "yellow",
    label: "معلق",
  },
  rejected: {
    color: "red",
    label: "مرفوض",
  },
  in_progress: {
    color: "blue",
    label: "جاري التنفيذ",
  },
};
