/** @format */
"use client";
import React, { useState } from "react";
import { PhoneInput } from "react-international-phone";
import { Button } from "antd";
import CreditCardForm from "./CardForm";
import "react-international-phone/style.css";
import clsx from "clsx";
import style from "./index.module.scss";

const tabs = [
  {
    title: "مدفوعات مدى:",
    img: "/assets/img/payment/mada.svg",
    comp: "card",
    key: "MADA",
  },
  {
    title: "البطاقات الإئتمانية:",
    img: "/assets/img/payment/visa.svg",
    comp: "card",
    key: "VISA_MASTER",
  },
  {
    title: "آبل باي:",
    img: "/assets/img/payment/Apple_Pay_logo.svg.webp",
    comp: "phone",
    key: "phone",
  },
  {
    title: "STC Pay:",
    img: "/assets/img/payment/stcpay.svg",
    comp: "phone",
    key: "phone",
  },
];
export default function Visa() {
  const [activeTab, setActiveTab] = useState<Record<string, string>>(tabs[0]);
  return (
    <div className={style.visaCont}>
      <div className={style.tabsCont}>
        {tabs.map((tab) => (
          <button
            className={clsx(tab.title === activeTab.title && style.activeTab)}
            key={tab.title}
            onClick={() => setActiveTab(tab)}>
            <img
              src={tab.img}
              alt={tab.title}
              className='w-100 h-100'
              width={72}
              height={24}
            />
          </button>
        ))}
      </div>
      <h3>{activeTab.title}</h3>
      {activeTab.comp === "card" ? <CreditCardForm /> : <Phone />}
    </div>
  );
}
const Phone = () => {
  return (
    <div className={style.phoneCont}>
      <PhoneInput
        className={style.phoneInput}
        placeholder='Please Enter your phone number'
        defaultCountry='eg'
        // value={phone}
        // onChange={(value) => setValue("phone", value)}
      />

      {/* <div className='form-check'>
        <input
          className='form-check-input'
          type='checkbox'
          value=''
          id='flexCheckDefault'
        />
        <label className='form-check-label'>
          I acknowledge that I have read and agree to the store's{" "}
        </label>
      </div> */}
      <Button color='primary' variant='link'>
        تأكيد الدفع
      </Button>
    </div>
  );
};
