import { AxiosResponse } from "axios";
import { TResponse } from "../types/api";
import { TRole, TRoleBody } from "../types/roles";
import api from "./api";

const rolesService = {
  getAllRoles: async (
    searchQuery?: string
  ): Promise<AxiosResponse<TResponse<TRole[]>>> =>
    await api.get(`/roles`, { params: { name: searchQuery } }),

  addRole: async (body: TRoleBody): Promise<AxiosResponse<TResponse<TRole>>> =>
    await api.post("/roles", body),

  updateRole: async ({
    id,
    body,
  }: {
    id: number;
    body: TRoleBody;
  }): Promise<AxiosResponse<TResponse<TRole>>> =>
    await api.post(`/roles/${id}/update`, body),

  changeRoleStatus: async ({
    id,
    status,
  }: {
    id: number;
    status: "active" | "inactive";
  }) => await api.post(`/roles/${id}/change-status`, { status }),

  deleteRole: async (id: number): Promise<AxiosResponse<TResponse<[]>>> =>
    await api.delete(`/roles/${id}/delete`),

  getRoleDetails: async (
    id: number
  ): Promise<AxiosResponse<TResponse<TRole>>> => await api.get(`/roles/${id}`),
};

export default rolesService;
