import { Form, TabsProps } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateProfile } from "../../../hooks/profile";
import { TAttachment } from "../../../types/attachment";
import { TUser } from "../../../types/auth";
import CustomTabs from "../../Common/CustomTabs/CustomTabs";
import ImageUploader from "../../Common/ImageUploader";
import SectionCard from "../../Common/SectionCard/SectionCard";
import FormActionButtons from "../../FormActionButtons/FormActionButtons";
import EditDetails from "../EditDetails/EditDetails";
import UploadDocs from "../UploadDocs/UploadDocs";
import styles from "./EditProfileForm.module.scss";

type TProps = {
  data?: TUser;
  isLoading: boolean;
};
export default function EditProfileForm({ data, isLoading }: TProps) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [logo, setLogo] = useState(data?.logo || "");
  const { mutate: onUpdateProfile, isLoading: updateProfileLoader } =
    useUpdateProfile(navigate);

  const attachmentsList: TAttachment[] =
    data?.attachments?.map((item) => ({
      ...item,
      name: item.type,
    })) || [];

  const items: TabsProps["items"] = [
    {
      key: "docs",
      label: "البيانات الأساسية",
      children: <EditDetails form={form} data={data} />,
    },
    {
      key: "history",
      label: "المستندات ",
      children: <UploadDocs form={form} data={attachmentsList} />,
    },
  ];
  const handleSubmit = (data: any) => {
    const formData = new FormData();

    for (let key in data) {
      if (key === "logo" && typeof data[key] === "string") continue;

      if (data[key]?.originFileObj || data[key])
        formData.append(key, data[key]?.originFileObj || data[key]);
    }

    onUpdateProfile(formData);
  };

  const handleLogoChange = (base64: string | null, file: File) => {
    if (base64) setLogo(base64);
    form.setFieldValue("logo", file);
  };
  const handleCancel = () => {
    navigate(-1);
    form.resetFields();
  };
  const formID = "editProfileForm";

  console.log({ data });
  return (
    <Form
      onChange={console.log}
      onFinish={(values) => {
        console.log(values);
        handleSubmit(values);
      }}
      initialValues={{
        ...data,
        country_id: data?.country?.id,
        city_id: data?.city?.id,
        region_id: data?.city?.region_id,
        phone: data?.phone?.toString(),
      }}
      form={form}
      id={formID}
      className={styles.editProfileForm}
    >
      <Form.Item name="logo" hidden />
      <Form.Item name="tax_card" hidden />
      <Form.Item name="more" hidden />
      <Form.Item name="identity" hidden />
      <Form.Item name="commercial_register" hidden />

      <div className={styles.header}>
        <FormActionButtons
          formID={formID}
          onClickCancel={handleCancel}
          loading={updateProfileLoader}
        />
      </div>

      <div className={styles.content}>
        <div className={styles.inputs}>
          <SectionCard>
            <CustomTabs
              isTabBold
              rootClassName={styles.tabs}
              defaultActiveKey={"docs"}
              items={items}
            />
          </SectionCard>
        </div>
        <div className={styles.logo}>
          <SectionCard title="شعار الشركة ">
            <div className={styles.imgUploaderContainer}>
              <p>أضف صورة الشعار (لوجو)</p>
              <ImageUploader
                className={styles.imgUploader}
                setImage={handleLogoChange}
                image={logo}
              />
            </div>
          </SectionCard>
        </div>
      </div>
    </Form>
  );
}
