import type { ButtonProps, TableProps } from "antd";
import { ConfigProvider, Table } from "antd";
import clsx from "clsx";
import { ReactNode } from "react";
import { HiPlusSmall } from "react-icons/hi2";
import CustomBtn from "../CustomBtn/CustomBtn";
import styles from "./CustomTable.module.scss";
type TProps = TableProps & {
  heading?: ReactNode;
  buttonProps?: ButtonProps & { btnValue: string };
  configStyle?: any;
};

function CustomTable<T extends { id: number }>(props: TProps) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: "var(--color-light-gray)",
            fontSize: 13.2,
            fontWeightStrong: 400,
            ...props.configStyle,
          },
        },
      }}
    >
      <div className={clsx(styles.customTable, props.className)}>
        <div className={styles.header}>
          {props.heading}
          {props.buttonProps && (
            <CustomBtn
              icon={props.buttonProps.icon || <HiPlusSmall />}
              rootClassName={styles.btn}
              {...props.buttonProps}
            >
              {props.buttonProps.btnValue}
            </CustomBtn>
          )}
        </div>
        <Table<T>
          {...props}
          rowKey={(row) => row.id}
          rootClassName={clsx(styles.table)}
          pagination={{ ...props.pagination, hideOnSinglePage: true }}
          scroll={{ x: "max-content" }}
        />
      </div>
    </ConfigProvider>
  );
}

export default CustomTable;
