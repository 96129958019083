import { AxiosResponse } from "axios";
import { TResponse } from "../types/api";
import { TEmployee, TUpdateEmployeeStatus } from "../types/employees";
import api from "./api";

const employeesServices = {
  getAllEmployees: async (params?: {
    name: string;
  }): Promise<AxiosResponse<TResponse<TEmployee[]>>> =>
    await api.get("/admin-list", { params }),

  updateEmployeeStatus: async ({ id, status }: TUpdateEmployeeStatus) =>
    await api.put(`/admin-list/${id}/update-status`, { status }),

  deleteEmployee: async (id: number) =>
    await api.delete(`/admin-list/${id}/delete`),

  createEmployee: async (body: FormData) => await api.post(`/admin-list`, body),

  getEmployeeDetails: async (
    id: number
  ): Promise<AxiosResponse<TResponse<TEmployee>>> =>
    await api.get(`/admin-list/${id}/show`),

  updateEmployee: async ({ body, id }: { body: FormData; id: number }) =>
    await api.post(`/admin-list/${id}/update`, body),
};

export default employeesServices;
