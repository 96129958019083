import clsx from "clsx";
import CustomBtn from "../Common/CustomBtn/CustomBtn";
import styles from "./FormActionButtons.module.scss";

type TProps = {
  onClickCancel: () => void;
  formID: string;
  loading?: boolean;
  saveBtnText?: string;
  cancelBtnText?: string;
  className?: string;
};
function FormActionButtons({
  onClickCancel,
  loading = false,
  saveBtnText = "حفظ",
  cancelBtnText = "إلغاء",
  className,
  formID,
}: TProps) {
  return (
    <div className={clsx(styles.formActionButtons, className)}>
      <CustomBtn
        form={formID}
        disabled={loading}
        loading={loading}
        htmlType="submit"
      >
        {saveBtnText}
      </CustomBtn>
      <CustomBtn
        disabled={loading}
        size="middle"
        className={styles.cancelBtn}
        onClick={onClickCancel}
      >
        {cancelBtnText}
      </CustomBtn>
    </div>
  );
}

export default FormActionButtons;
