import { AxiosResponse } from "axios";
import { TResponse } from "../types/api";
import { TCurrency } from "../types/TLookups";
import api from "./api";

const currenciesServices = {
  getAllCurrencies: async (): Promise<AxiosResponse<TResponse<TCurrency[]>>> =>
    api.get("/currencies"),

  changeCurrency: async (currency_id: number): Promise<AxiosResponse> =>
    api.post(`/currencies/storeDefault`, { currency_id }),
};
export default currenciesServices;
