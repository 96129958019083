/** @format */

import { Alert } from "antd";
import { useGetProfile } from "../../../hooks/profile";
import SectionCard from "../../Common/SectionCard/SectionCard";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import SellerDetails from "../SellerDetails/SellerDetails";
import ProfileTabs from "../Tabs/Tabs";
import styles from "./ViewProfileContent.module.scss";

function ViewProfileContent() {
  const { data, isLoading } = useGetProfile();

  return (
    <div className={styles.profile}>
      <ProfileHeader data={data} />
      <div className={styles.content}>
        <div className={styles.sellerDetails}>
          <SellerDetails data={data} />
        </div>
        <div className={styles.tabs}>
          <ProfileTabs data={data} isLoading={isLoading} />
          {data?.approval_status === "rejected" && (
            <SectionCard
              title="اسباب الرفض"
              rootClassName={styles.rejectionReasons}
            >
              <ul className={styles.list}>
                {data?.reject_reasons?.map((item) => (
                  <li key={item.id} className={styles.listItem}>
                    <Alert banner message={item.reason} type="warning" />
                  </li>
                ))}
              </ul>
            </SectionCard>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewProfileContent;
