/** @format */

import type { TableProps } from "antd";
import { Table } from "antd";
import { useGetProfile } from "../../../hooks/profile";
import { TProduct } from "../../../types/products";
import style from "../style.module.scss";
interface DataType {
  key: string;
  name: string;
  cost: number;
  profit: number;
  price: number;
  tax: number;
  total: number;
}

export default function Prices({
  data,
  isLoading,
}: {
  data: TProduct[];
  isLoading: boolean;
}) {
  const { data: profile } = useGetProfile();
  const columns: TableProps<TProduct>["columns"] = [
    {
      title: "الرقم التعريفي",
      dataIndex: "id",
      key: "id",
      render: (text) => <b>{text}</b>,
    },
    {
      title: () => <p className={style.productHeader}>المنتج</p>,
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <div className={style.productInfo}>
          <div>
            <img src={record.image} alt={record.name} />
          </div>
          <span> {record.name} </span>
        </div>
      ),
    },
    {
      title: "العلامة التجارية",
      dataIndex: "brand",
      key: "brand",
      render: (_, record) => <span>{record.brand?.name || "-"}</span>,
    },
    {
      title: "سعر الجملة",
      dataIndex: "wholesale_price",
      key: "wholesale_price",
      render: (text) => <b>{Number(text).toFixed(2)}</b>,
    },
    {
      title: "نسبة الربح",
      dataIndex: "profit_rate",
      key: "profit_rate",
      render: (text) => <span>{Number(text).toFixed(3)}%</span>,
    },
    {
      title: "السعر قبل الضريبة",
      key: "price",
      dataIndex: "price",
      render: (_, record) => (
        <p className={style.price}>
          {(record.price - record.price * record.tax_rate).toFixed(2)}
        </p>
      ),
    },
    {
      title: "العمله",
      key: "currency",
      dataIndex: "currency",
      render: (text) => <b>{profile?.currency.code}</b>,
    },
    {
      title: "القيمة الأسمية",
      key: "price",
      dataIndex: "price",
      render: (text) => <b>{text.toFixed(2)}</b>,
    },
  ];
  return (
    <div className={style.productTableCont}>
      <Table<TProduct>
        scroll={{ x: "max-content" }}
        rowKey={(row) => row.id}
        columns={columns}
        dataSource={data}
        loading={isLoading}
      />
    </div>
  );
}
