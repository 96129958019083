/** @format */

import React from "react";
import ProductsList from "../../components/Products";

export default function Products() {
  return (
    <div>
      <ProductsList />
    </div>
  );
}
