/** @format */

import type { TabsProps } from "antd";
import { Tabs } from "antd";
import Box from "../../components/Common/Box/index";
import Cash from "./cash";
import styles from "./style.module.scss";
import Visa from "./Visa/index";

export default function Wallet() {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "نقدي",
      children: <Cash />,
    },
    {
      key: "2",
      label: "فيزا",
      children: <Visa />,
    },
  ];

  return (
    <div className={styles.walletPage}>
      <Box title='شحن الرصيد'>
        <div className={styles.tabsCont}>
          <Tabs
            defaultActiveKey='1'
            tabBarGutter={10}
            size={"small"}
            type='card'
            items={items.map((item) => {
              return item;
            })}
          />
        </div>
      </Box>
    </div>
  );
}

// const TabLabel = ({ label }: { label: string }) => {
//   return (
//     <div className={styles.tabLabel}>
//       <label>{label}</label>
//     </div>
//   );
// };
