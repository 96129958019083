/** @format */

import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB5cpEf8kKYSdQtDTkODo7z0YysE1IDZbM",
  authDomain: "take-card.firebaseapp.com",
  projectId: "take-card",
  storageBucket: "take-card.firebasestorage.app",
  messagingSenderId: "164359120887",
  appId: "1:164359120887:web:ba3fe9733cebf6f548b0f2",
  measurementId: "G-07RK6MTCDX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    const token = await getToken(messaging, permission, {
      vapidKey:
        "BNWxNlQU52K8G80DwkiyCIjKh-ruGv-9KZ6DEU-4dIBpC9eWS2SJ0r5wVhizSxDgKP8TSq9iQw_jbEsSmPOQPNs",
    });
    return token;
  }
};
