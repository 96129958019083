import clsx from "clsx";
import { Dispatch, SetStateAction } from "react";
import CustomBtn from "../../Common/CustomBtn/CustomBtn";
import styles from "./ReportsTab.module.scss";

type TProps = {
  active: string;
  setActive: Dispatch<SetStateAction<string>>;
};
function ReportsTab({ active, setActive }: TProps) {
  const items = [
    {
      key: "products",
      label: "مبيعات المنتجات ",
    },
    {
      key: "time",
      label: "المبيعات خلال الفترات الزمنية",
    },
    {
      key: "brand",
      label: "مبيعات العلامات التجارية",
    },
    {
      key: "depart",
      label: "مبيعات الأقسام",
    },
    {
      key: "employee",
      label: "مبيعات الموظفين",
    },
  ];
  return (
    <div className={styles.reportsTab}>
      {items.map((item) => (
        <CustomBtn
          onClick={() => setActive(item.key)}
          key={item.key}
          className={clsx(styles.btn, active === item.key && styles.btnActive)}
        >
          {item.label}
        </CustomBtn>
      ))}
    </div>
  );
}

export default ReportsTab;
