/** @format */

import ForgetPassword from "../../../assets/lottie/lottie-forget-password.json";
import AuthLayout from "../../../components/auth/AuthLayout/AuthLayout";
import ResetPasswordForm from "../../../components/auth/Forms/ResetPasswordForm/ResetPasswordForm";
function ResetPassword() {
  document.title = "إعادة تهيئة كلمة المرور";
  return (
    <AuthLayout lottie={ForgetPassword}>
      <ResetPasswordForm formHeading=" إعادة تهيئة كلمة المرور  " />
    </AuthLayout>
  );
}
export default ResetPassword;
