import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { NavigateFunction, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import profileServices from "../services/profile";
import { tags } from "../services/tags";

export const useGetProfile = (navigate?: NavigateFunction) => {
  const { pathname } = useLocation();
  const {
    i18n: { language },
  } = useTranslation();

  const query = useQuery(
    tags.getProfile,
    async () => {
      const { data } = await profileServices.getProfile();
      return data.data;
    },
    {
      enabled: !pathname.includes("auth"),
      retry: false,
      onError: () => {
        navigate && navigate(`/${language}/auth/sign-in`);
      },
    }
  );
  return { ...query };
};

export const useUpdateProfile = (navigate: NavigateFunction) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(profileServices.updateProfile, {
    onSuccess: ({ data }) => {
      const { message } = data;
      toast.success(message);
      queryClient.invalidateQueries(tags.getProfile);
      navigate(-1);
    },
  });
  return { ...mutation };
};

export const useDeleteAttachment = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(profileServices.deleteAttachment, {
    onSuccess: ({ data }) => {
      const { message } = data;
      toast.success(message);
      queryClient.invalidateQueries(tags.getProfile);
    },
  });
  return { ...mutation };
};

export const useLoginUser = () => {
  const { data } = useGetProfile();

  return {
    isApproved: data?.approval_status === "approved",
    user: data,
  };
};
