export const sellerStatus = {
  approved: {
    label: "موثق",
    color: "green",
  },
  pending: {
    label: "معلق",
    color: "yellow",
  },
  rejected: {
    label: "غير موثق",
    color: "red",
  },
};
