import { Switch, SwitchProps } from "antd";
import clsx from "clsx";
import styles from "./CustomSwitcher.module.scss";

type TProps = SwitchProps & {
  label?: string;
};

function CustomSwitcher(props: TProps) {
  return (
    <div className={styles.switchContainer}>
      <span>{props.label}</span>
      <Switch className={clsx(styles.switch, props.className)} {...props} />
    </div>
  );
}

export default CustomSwitcher;
