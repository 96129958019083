import { ConfigProvider, Tabs, TabsProps } from "antd";

type TProps = TabsProps & {
  isTabBold?: boolean;
};

function CustomTabs({ isTabBold, ...props }: TProps) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            horizontalItemPadding: "10px",
          },
        },
      }}
    >
      <Tabs {...props} />
    </ConfigProvider>
  );
}

export default CustomTabs;
