/** @format */
import { useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useGoogleVerify } from "../../../../hooks/auth";
import CustomBtn from "../../../Common/CustomBtn/CustomBtn";
import CustomLink from "../../../Common/CustomLink/CustomLink";
import style from "./style.module.scss";

export default function GoogleAuthVerify() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const { mutate, isLoading } = useGoogleVerify(navigate);
  const verify = () => {
    const payload = {
      google2fa_secret: sessionStorage.getItem("secret"),
      ...JSON.parse(sessionStorage.getItem("registerData") || ""),
      otp,
    };
    mutate(payload);
  };
  return (
    <div className={style.googleAuthCont}>
      <div>
        <h2 className={style.formHeading}>تسجيل موثقي جوجل</h2>
      </div>
      <OtpInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        containerStyle={style.otpStyle}
        renderInput={(props) => <input {...props} />}
      />
      <div className={style.actionBtns}>
        <CustomBtn type='primary' rootClassName={style.cancelBtn}>
          <CustomLink to='/auth/sign-up'>إلغاء</CustomLink>
        </CustomBtn>
        <CustomBtn loading={isLoading} type='primary' onClick={() => verify()}>
          تحقق
        </CustomBtn>
      </div>
    </div>
  );
}
