/** @format */

import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { TFilters } from "../components/Store/Brands";
import { storeService } from "../services/store";
import { tags } from "../services/tags";
import {
  TAddToCart,
  TAddToFavPayload,
  TDeleteFromCart,
  TDeleteFromFavPayload,
} from "../types/store";
const getBrands = async (filters: TFilters, currentPage: number) => {
  const {
    data: { data },
  } = await storeService.getBrands(filters, currentPage);

  return data;
};
const getNewSubCategory = async (filters: TFilters, currentPage: number) => {
  const {
    data: { data },
  } = await storeService.getNewSubCategory(filters, currentPage);

  return data;
};
const getMainCategory = async () => {
  const {
    data: { data },
  } = await storeService.getMainCategory();
  return data;
};
const getCategory = async (categoryId?: string, brandId?: string) => {
  const {
    data: { data },
  } = await storeService.getCategory(categoryId, brandId);
  return data;
};
export const getFavProducts = async (filters: Record<string, string>) => {
  const {
    data: { data },
  } = await storeService.getFavProducts(filters);
  return data;
};
export const getProducts = async (
  categoryId: string,
  brandId: string,
  page: string,
  search: string
) => {
  const {
    data: { data },
  } = await storeService.getProducts(categoryId, brandId, page, search);
  return data;
};
export const getNewProducts = async (
  categoryId: string,
  brandId: string,
  page: string,
  search: string,
  per_page: string
) => {
  const {
    data: { data },
  } = await storeService.getNewProducts(
    categoryId,
    brandId,
    page,
    search,
    per_page
  );
  return data;
};

export const useBrands = (filters: TFilters, currentPage: number) => {
  const mutation = useQuery(
    ["getBrands", filters.name, filters.category_filter, currentPage],
    () => getBrands(filters, currentPage)
  );
  return { ...mutation };
};
export const useNewSubCategory = (filters: TFilters, currentPage: number) => {
  const mutation = useQuery(
    ["getBrands", filters.name, filters.category_filter, currentPage],
    () => getNewSubCategory(filters, currentPage)
  );
  return { ...mutation };
};
export const useMainCategory = () => {
  const mutation = useQuery("getMainCategory", () => getMainCategory());
  return { ...mutation };
};
export const useCategory = (categoryId?: string, brandId?: string) => {
  const mutation = useQuery(
    ["getCategory", categoryId, brandId],
    () => getCategory(categoryId, brandId),
    {
      enabled: !!categoryId,
    }
  );
  return { ...mutation };
};
export const useProducts = (
  categoryId: string,
  brandId: string,
  page: string,
  search: string
) => {
  const mutation = useQuery(
    [tags.getCategoryProducts, page, search, categoryId, brandId],
    () => getProducts(categoryId, brandId, page, search)
  );
  return { ...mutation };
};
export const useNewProducts = (
  categoryId: string,
  brandId: string,
  page: string,
  search: string,
  per_page: string
) => {
  const mutation = useQuery(
    [tags.getCategoryProducts, page, search, categoryId, brandId, per_page],
    () => getNewProducts(categoryId, brandId, page, search, per_page)
  );
  return { ...mutation };
};
export const useFav = (filters: Record<string, string>) => {
  const mutation = useQuery(
    [tags.getFavProd, filters.page, filters.pageSize],
    () => getFavProducts(filters)
  );
  return { ...mutation };
};
export const useAddToFav = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (payload: TAddToFavPayload) => storeService.addToFav(payload),
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries(tags.getFavProd);
        queryClient.invalidateQueries(tags.getCategoryProducts);

        toast.success(data.data.message);
      },
    }
  );

  return { ...mutation };
};
export const useDeleteFromFav = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (payload: TDeleteFromFavPayload) => storeService.deleteFromFav(payload),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(tags.getFavProd);
        queryClient.invalidateQueries(tags.getCategoryProducts);

        toast.success(data.data.message);
      },
    }
  );

  return { ...mutation };
};
const getCart = async () => {
  const {
    data: { data },
  } = await storeService.getCarts();
  return data;
};
export const useCart = () => {
  const mutation = useQuery(tags.getCart, getCart);
  return { ...mutation };
};
export const useAddToCart = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (payload: TAddToCart) => storeService.addToCart(payload),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(tags.getCart);
        if (onSuccess) onSuccess();
        // toast.success(data.data.message);
      },
    }
  );
  return { ...mutation };
};
export const useDeleteFromCart = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (payload: TDeleteFromCart) => storeService.deleteFrom(payload),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(tags.getCart);
        onSuccess();
        toast.success(data.data.message);
      },
    }
  );
  return { ...mutation };
};
export const useCreateOrder = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(() => storeService.createOrder(), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(tags.getCart);
      if (onSuccess) onSuccess();
      toast.success(data.data.message);
    },
  });
  return { ...mutation };
};
