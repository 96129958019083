/** @format */

import { useMutation, useQuery, useQueryClient } from "react-query";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { cashService } from "../services/cash";
import { tags } from "../services/tags";
const getBalanceList = async () => {
  const {
    data: { data },
  } = await cashService.getBalanceList();
  return data;
};

export const useGetBalance = () => {
  const mutation = useQuery(tags.getBalance, () => getBalanceList());
  return { ...mutation };
};
const getBanks = async () => {
  const {
    data: { data },
  } = await cashService.getBanks();
  return data;
};

export const useGetBanks = () => {
  const mutation = useQuery("getBanks", () => getBanks());
  return { ...mutation };
};
export const useCashReceipt = (navigate: NavigateFunction) => {
  const queryClinet = useQueryClient();
  const mutation = useMutation(
    (payload: FormData) => cashService.addReceipt(payload),
    {
      onSuccess: (data) => {
        queryClinet.invalidateQueries(tags.getProfile);
        queryClinet.invalidateQueries(tags.getBalance);
        queryClinet.invalidateQueries(tags.getHome);
        toast.success(data.data.message);
        navigate("/balance-list");
      },
    }
  );

  return { ...mutation };
};
