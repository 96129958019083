import { Player } from "@lordicon/react";
import { useEffect, useRef } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { IoMdOptions } from "react-icons/io";
import { RiFileExcel2Line } from "react-icons/ri";
import Bag from "../../../assets/lottie/bag.json";
import Coin from "../../../assets/lottie/coin.json";
import Trash from "../../../assets/lottie/trash.json";
import CustomBtn from "../../Common/CustomBtn/CustomBtn";
import styles from "./ReportsActions.module.scss";

type TProps = {
  activeTab: string;
};
function ReportsActions({ activeTab }: TProps) {
  const playerRef1 = useRef<Player>(null);
  const playerRef2 = useRef<Player>(null);
  const playerRef3 = useRef<Player>(null);

  useEffect(() => {
    playerRef1.current?.playFromBeginning();
    playerRef2.current?.playFromBeginning();
    playerRef3.current?.playFromBeginning();
  }, [activeTab]);

  return (
    <div className={styles.reportsActions}>
      <div className={styles.filters}>
        <CustomBtn icon={<IoMdOptions />} className={styles.filterBtn}>
          تصفية
        </CustomBtn>
        <div className={styles.export}>
          <b>تصدير :</b>
          <div className={styles.exportType}>
            <span>PDF</span>
            <FaRegFilePdf />
          </div>
          <div className={styles.exportType}>
            <span>Excel</span>
            <RiFileExcel2Line />
          </div>
        </div>
      </div>
      <div className={styles.divider} />
      {activeTab === "products" && (
        <ul className={styles.stats}>
          <li>
            <Player
              onComplete={() => playerRef1.current?.playFromBeginning()}
              size={55}
              ref={playerRef1}
              icon={Bag}
              colors="primary:#6691e7,secondary:#fb8b02"
            />
            <b className={styles.total}>1,374,858</b>&nbsp;
            <span>ر.س</span>
            <p>إجمالي المبيعات</p>
          </li>
          <li>
            <Player
              onComplete={() => playerRef2.current?.playFromBeginning()}
              size={55}
              ref={playerRef2}
              icon={Trash}
              colors="primary:#6691e7,secondary:#fb8b02"
            />
            <b className={styles.total}>1,374,858</b>
            <p> الكمية المباعة</p>
          </li>
          <li>
            <Player
              onComplete={() => playerRef3.current?.playFromBeginning()}
              size={55}
              ref={playerRef3}
              icon={Coin}
              colors="primary:#6691e7,secondary:#fb8b02"
            />
            <b className={styles.total}>1,374,858</b>&nbsp;
            <span>ر.س</span>
            <p> تكلفة الكمية المباعة</p>
          </li>
        </ul>
      )}
    </div>
  );
}

export default ReportsActions;
