import { useNavigate } from "react-router-dom";
import UnAuthPic from "../../assets/gif/unautherized.gif";
import CustomBtn from "../../components/Common/CustomBtn/CustomBtn";
import styles from "./index.module.scss";

export default function UnAuth() {
  const navigate = useNavigate();
  return (
    <div className={styles.unAuth}>
      <div className={styles.content}>
        <img src={UnAuthPic} alt="unautherized" />
        <CustomBtn
          type="primary"
          htmlType="submit"
          onClick={() => navigate(-2)}
        >
          رجوع
        </CustomBtn>
      </div>
    </div>
  );
}
