import { Player } from "@lordicon/react";
import { Modal, ModalProps } from "antd";
import { useEffect, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import DeleteLottie from "../../../assets/lottie/delete.json";
import CustomBtn from "../CustomBtn/CustomBtn";
import styles from "./DeleteModal.module.scss";

type TProps = ModalProps;
function DeleteModal({ centered, ...props }: TProps) {
  const playerRef1 = useRef<Player>(null);
  useEffect(() => {
    playerRef1.current?.playFromBeginning();
  }, [props.open]);
  return (
    <Modal
      classNames={{ content: styles.deleteModal, footer: styles.footer }}
      {...props}
      loading={false}
      centered
      open={props.open}
      footer={null}
    >
      <div className={styles.content}>
        <div className={styles.lottie}>
          <Player
            onComplete={() => playerRef1.current?.playFromBeginning()}
            size={90}
            ref={playerRef1}
            icon={DeleteLottie}
            colors="primary:#405189,secondary:#f06548"
          />
        </div>
        <h3 className={styles.title}>هل انتا متأكد من هذا الاجراء ؟</h3>
        <p className={styles.text}>
          سيؤدي الحذف إلى إزالة جميع المعلومات من قاعدة البيانات الخاصة بنا.
        </p>
        <div className={styles.btns}>
          <CustomBtn
            loading={props.loading}
            onClick={props.onOk}
            className={styles.deleteBtn}
          >
            نعم انا متأكد
          </CustomBtn>
          <CustomBtn
            onClick={props.onCancel}
            icon={<IoMdClose />}
            className={styles.cancelBtn}
          >
            الغاء
          </CustomBtn>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteModal;
