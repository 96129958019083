import { TableProps } from "antd";
import { useMemo } from "react";
import CustomLink from "../../Common/CustomLink/CustomLink";
import CustomTable from "../../Common/CustomTable/CustomTable";
import styles from "./ProductSalesTable.module.scss";

function ProductSalesTable() {
  const columns: TableProps<any>["columns"] = useMemo(
    () => [
      {
        title: "اسم المنتج",
        dataIndex: "name",
        key: "name",
        minWidth: 350,
        render: (_, record) => (
          <CustomLink to="/" className={styles.link}>
            #VZ2101
          </CustomLink>
        ),
      },
      {
        title: "العلامة التجارية",
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: "الكمية المباعة	",
        dataIndex: "cardsNumber",
        key: "cardsNumber",
      },
      {
        title: "تكلفة الكمية المباعة	",
        dataIndex: "total",
        key: "total",
        render: (_, record) => (
          <p>
            <b>1236.00</b>
            &nbsp; ر.س
          </p>
        ),
      },
      {
        title: "إجمالي المبيعات	",
        dataIndex: "status",
        key: "status",
      },
    ],
    []
  );
  return (
    <div className={styles.productSalesTable}>
      <CustomTable
        columns={columns}
        className={styles.customTable}
        configStyle={{
          fontWeightStrong: 500,
          headerColor: "#878A99",
          padding: 10,
        }}
      />
    </div>
  );
}

export default ProductSalesTable;
