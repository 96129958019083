import { useState } from "react";

function useFullScreen(): [boolean, () => void] {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const onToggleFullScreen = () => {
    const doc = document.documentElement;

    if (!isFullScreen) {
      doc.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    setIsFullScreen((prev) => !prev);
  };

  return [isFullScreen, onToggleFullScreen];
}

export default useFullScreen;
