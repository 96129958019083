/** @format */
import clsx from "clsx";
import { BiCartDownload, BiWallet } from "react-icons/bi";
import { BsCash } from "react-icons/bs";
import { useGetProfile } from "../../../hooks/profile";

import { THome } from "../../../types/products";
import style from "./style.module.scss";
const cards = [
  {
    label: "عدد الطلبات",
    key: "order_count",
    subText: "طلب",
    icon: <BiCartDownload />,
    className: "orders",
  },
  {
    label: "إجمالي قيمة الطلبات",
    key: "total_value_of_orders",
    subText: "ريال سعودي",
    icon: <BsCash />,
    className: "cash",
  },
  {
    label: "رصيد المحفظة",
    key: "total_of_wallets",
    subText: "ريال سعودي",
    icon: <BiWallet />,
    className: "wallet",
  },
];

export default function Statistics({ data }: { data: THome }) {
  return (
    <div className={style.cont}>
      {cards.map((card) => (
        <Card
          key={card.label}
          card={card}
          value={data[card.key as keyof THome]}
        />
      ))}
    </div>
  );
}
const Card = ({ card, value }: { card: any; value: any }) => {
  const { data: profile } = useGetProfile();
  return (
    <div className={style.card}>
      <div className={style.cardInfo}>
        <h3>{card.label}</h3>
        <p>{value}</p>
        <span>{card.subText === "طلب" ? "طلب" : profile?.currency.name}</span>
      </div>

      <div className={clsx(style.icon, style[card.className])}>{card.icon}</div>
    </div>
  );
};
