/** @format */

import SignUpLottie from "../../../assets/lottie/lottie-sign-up.json";
import AuthLayout from "../../../components/auth/AuthLayout/AuthLayout";
import GoogleAuth from "../../../components/auth/Forms/GoogleAuth/GoogleAuth";

function Page() {
  document.title = "تسجيل موثقي جوجل";
  return (
    <AuthLayout lottie={SignUpLottie}>
      <GoogleAuth />
    </AuthLayout>
  );
}
export default Page;
