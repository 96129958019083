/** @format */

import { Modal, Table, TableProps } from "antd";
import clsx from "clsx";
import dayjs from "dayjs";
import { useState } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import useAuthorization from "../../hooks/useAuthorization";
import { useGitTicket } from "../../hooks/useTicket";
import { TTicket } from "../../types/attachment";

import Box from "../Common/Box";
import CustomLink from "../Common/CustomLink/CustomLink";
import style from "./style.module.scss";
const mapStatus: Record<string, Record<string, string>> = {
  ar: {
    pending: "جاري العمل",
    new: "جديد",
    closed: "مغلق",
  },
  en: {
    pending: "Pending",
    new: "New",
    closed: "Closed",
  },
};
const mapStatusToStyle: Record<string, string> = {
  pending: style.pending,
};
export default function SupportPage() {
  const [showTicket, toggleTicketReplay] = useState<boolean>(false);
  const [selectedTicket, setTicket] = useState<TTicket | undefined>();
  const { data, isLoading } = useGitTicket();
  const openReplay = (record?: TTicket) => {
    setTicket(record);
    toggleTicketReplay(!showTicket);
  };
  const columns: TableProps<TTicket>["columns"] = [
    {
      title: "الرقم التعريفي",
      dataIndex: "id",
      key: "id",
      render: (text) => <b>{text}</b>,
    },
    {
      title: () => <p className={style.productHeader}>تاريخ الإضافة</p>,
      dataIndex: "created_at",
      key: "created_at",
      render: (text: string) => (
        <p className={style.createdAt}>
          {dayjs(text).format("DD-MMMM-YYYY HH:mm a")}
        </p>
      ),
    },
    {
      title: "عنوان التذكرة",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "	رقم الطلب",
      key: "orderNumber",
      dataIndex: "orderNumber",
      render: (text: number) => <b className={style.ticketNum}>#{text}</b>,
    },
    {
      title: "الحالة",
      key: "status",
      dataIndex: "status",
      render: (text: string) => (
        <p className={clsx(style.status, mapStatusToStyle[text])}>
          {mapStatus.ar[text]}
        </p>
      ),
    },
    {
      title: "التعليق",
      key: "replies",
      dataIndex: "replies",
      render: (_, record) =>
        !record.replies.length ? (
          <BsFillEyeFill
            onClick={() => openReplay(record)}
            className={style.eye}
          />
        ) : null,
    },
  ];
  const { hasPermission } = useAuthorization();
  return (
    <Box title='الدعم'>
      <>
        {hasPermission("AddTicket") ? (
          <CustomLink to={"/support/add-ticket"}>
            <button className={style.addTicket}> إضافة تذكرة +</button>
          </CustomLink>
        ) : null}

        <div className={style.ticketTable}>
          <Table<TTicket>
            className={style.balanceTable}
            columns={columns}
            pagination={false}
            dataSource={data}
            loading={isLoading}
          />
          <Modal
            title='الرد على التذكرة'
            open={showTicket}
            cancelText='إغلاق'
            okButtonProps={{ style: { display: "none" } }}
            cancelButtonProps={{
              style: { border: "none", backgroundColor: "#f3f6f9" },
            }}
            onCancel={() => toggleTicketReplay(false)}>
            <h3>عنوان الشكوى</h3>
            {selectedTicket?.replies.toString()}
          </Modal>
        </div>
      </>
    </Box>
  );
}
