/** @format */

"use client";
import { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import authService from "../services/auth";
import { TResponse } from "../types/api";
import { TUser } from "../types/auth";
import useValidateAllTags from "./useValidateAllTags";

export const useSignIn = (navigate: NavigateFunction) => {
  const { i18n } = useTranslation();
  const { validateAllTags } = useValidateAllTags();
  const mutation = useMutation(authService.signIn, {
    onSuccess: (data) => {
      onSuccess(data, navigate, i18n.language);
      validateAllTags();
    },
  });
  return { ...mutation };
};

export const useSignUp = (navigate: NavigateFunction) => {
  const { i18n } = useTranslation();
  const { validateAllTags } = useValidateAllTags();

  const mutation = useMutation(authService.signUp, {
    onSuccess: (data) => {
      // onSuccess(data, navigate, i18n.language);
      // validateAllTags();
      toast.success(data.data.message);

      sessionStorage.setItem("secret", data.data.data.secret);

      sessionStorage.setItem("qrCodeUrl", data.data.data.qrCodeUrl);
      navigate(`/${i18n.language}/auth/google-sign-up`);
    },
  });

  return { ...mutation };
};

export const useGoogleVerify = (navigate: NavigateFunction) => {
  const { i18n } = useTranslation();
  const { validateAllTags } = useValidateAllTags();

  const mutation = useMutation(authService.register, {
    onSuccess: (data) => {
      onSuccess(data, navigate, i18n.language);
      validateAllTags();
    },
  });

  return { ...mutation };
};
export const useLogout = (navigate: NavigateFunction) => {
  const { i18n } = useTranslation();
  const mutation = useMutation(authService.logout, {
    onSuccess: (data) => {
      if (data.data.status) {
        Cookies.remove("token");
        navigate(`/${i18n.language}/auth/sign-in`);
      }
    },
  });

  return { ...mutation };
};

export const useForgetPassword = (navigate: NavigateFunction) => {
  const { i18n } = useTranslation();

  const mutation = useMutation(authService.forgetPassword, {
    onSuccess: ({ data: res }) => {
      const { status, message, data } = res;
      if (status) {
        toast.success(message);
        navigate(`/${i18n.language}/auth/reset-password?email=${data}`);
      }
    },
  });

  return { ...mutation };
};

export const useResetPassword = (navigate: NavigateFunction) => {
  const { i18n } = useTranslation();

  const mutation = useMutation(authService.resetPassword, {
    onSuccess: ({ data: res }) => {
      const { status, message } = res;
      if (status) {
        toast.success(message);
        navigate(`/${i18n.language}/auth/sign-in`);
      }
    },
  });

  return { ...mutation };
};

const onSuccess = (
  data: AxiosResponse<TResponse<TUser>>,
  navigate: NavigateFunction,
  lang: string
) => {
  if (data?.data?.status) {
    Cookies.set("token", data.data.token);
    toast.success(data.data.message);
    navigate(`/${lang}`);
  }
};
