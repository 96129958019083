export const Permissions = {
  HomeSection: "home-section",
  WalletAddBalance: "wallet-add-balance",
  WalletBalanceList: "wallet-balance-list",
  ProductsList: "products-list",
  Store: "store",
  FavoritesProductsList: "favorites-products-list",
  DeleteProductFav: "delete-product-fav",
  AddProductFav: "add-product-fav",
  OrdersList: "orders-list",
  AddOrder: "add-order",
  ViewOrder: "view-order",
  Reports: "reports",
  SettingViewProfile: "setting-view-profile",
  SettingUpdateProfile: "setting-update-profile",
  SettingAdminList: "setting-admin-list",
  SettingAddAdminList: "setting-add-admin-list",
  SettingEditAdminList: "setting-edit-admin-list",
  SettingDeleteAdminList: "setting-delete-admin-list",
  SettingChangeStatusAdminList: "setting-change-status-admin-list",
  SettingRolesList: "setting-roles-list",
  SettingAddRole: "setting-add-role",
  SettingEditRole: "setting-edit-role",
  SettingDeleteRole: "setting-delete-role",
  SettingShowRole: "setting-show-role",
  SettingChangeStatusRole: "setting-change-status-role",
  TicketsList: "tickets-list",
  AddTicket: "add-ticket",
  ViewTicket: "view-ticket",
};

export const PermissionsCategoriesList = [
  {
    name: "الصفحة الرئيسية",
    items: [Permissions.HomeSection],
  },
  {
    name: "المحفظة",
    items: [Permissions.WalletAddBalance, Permissions.WalletBalanceList],
  },
  {
    name: "المنتجات",
    items: [Permissions.ProductsList],
  },
  {
    name: "الملف الشخصي",
    items: [Permissions.SettingViewProfile, Permissions.SettingUpdateProfile],
  },
  {
    name: "المتجر",
    items: [Permissions.Store],
  },
  {
    name: "المفضلة",
    items: [
      Permissions.FavoritesProductsList,
      Permissions.AddProductFav,
      Permissions.DeleteProductFav,
    ],
  },
  {
    name: "الطلبات",
    items: [
      Permissions.OrdersList,
      Permissions.AddOrder,
      Permissions.ViewOrder,
    ],
  },
  {
    name: "التقارير",
    items: [Permissions.Reports],
  },
  {
    name: "الدعم",
    items: [
      Permissions.TicketsList,
      Permissions.AddTicket,
      Permissions.ViewTicket,
    ],
  },
  {
    name: "الادوار",
    items: [
      Permissions.SettingRolesList,
      Permissions.SettingAddRole,
      Permissions.SettingEditRole,
      Permissions.SettingDeleteRole,
      Permissions.SettingShowRole,
      Permissions.SettingChangeStatusRole,
    ],
  },
  {
    name: "الموظفون",
    items: [
      Permissions.SettingAdminList,
      Permissions.SettingAddAdminList,
      Permissions.SettingEditAdminList,
      Permissions.SettingDeleteAdminList,
      Permissions.SettingChangeStatusAdminList,
    ],
  },
];
