import { Button } from "antd";
import { ButtonProps } from "antd/es/button/button";
import clsx from "clsx";
import styles from "./CustomBtn.module.scss";

type TProps = ButtonProps;

function CustomBtn({ ...props }: TProps) {
  return (
    <Button className={clsx(styles.CustomBtn, props.className)} {...props}>
      {props.children}
    </Button>
  );
}

export default CustomBtn;
