/** @format */

import clsx from "clsx";
import { FaRegEnvelope } from "react-icons/fa";
import { LuPhone } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import {
  useDeleteEmployee,
  useUpdateEmployeeStatus,
} from "../../../hooks/employee";
import useAuthorization from "../../../hooks/useAuthorization";
import { TEmployee } from "../../../types/employees";
import CustomSwitcher from "../../Common/CustomSwitcher/CustomSwitcher";
import RowActions from "../../Common/TableComponents/RowActions/RowActions";
import styles from "./EmployeeCard.module.scss";

type TProps = {
  data: TEmployee;
};

function EmployeeCard({ data }: TProps) {
  const { mutate: updateStatus, isLoading: updateStatusLoader } =
    useUpdateEmployeeStatus();

  const { mutate: deleteEmployee, isLoading: deleteEmployeeLoader } =
    useDeleteEmployee();

  const navigate = useNavigate();
  const { hasPermission } = useAuthorization();

  const handleStatusChange = (val: boolean) => {
    console.log(val);
    updateStatus({
      id: data.id,
      status: val ? "active" : "inactive",
    });
  };

  console.log({ data });

  return (
    <div
      style={{ opacity: updateStatusLoader ? 0.5 : 1 }}
      className={styles.employeeCard}
    >
      <div className={styles.profileInfo}>
        <div className={styles.imgBox}>
          <img src={data.logo} alt="employee" />
        </div>
        <div className={styles.details}>
          <h3 className={styles.name}>{data.name}</h3>
          <p className={styles.role}> {data?.roles?.[0]}</p>
        </div>
      </div>
      <div className={styles.moreDetails}>
        <p>{data.phone || "غير موجود"}</p>
        <LuPhone className={styles.icon} />
      </div>
      <div className={styles.moreDetails}>
        <p>{data.email}</p>
        <FaRegEnvelope className={styles.icon} />
      </div>
      {hasPermission("SettingChangeStatusAdminList") && (
        <div className={clsx(styles.moreDetails, styles.status)}>
          <CustomSwitcher
            loading={updateStatusLoader}
            checked={data.status === "active"}
            onChange={handleStatusChange}
          />
        </div>
      )}
      <RowActions
        isLoading={deleteEmployeeLoader}
        onDelete={
          hasPermission("SettingDeleteAdminList")
            ? () => deleteEmployee(data.id)
            : undefined
        }
        onEdit={
          hasPermission("SettingEditAdminList")
            ? () => navigate(`/settings/Employees/edit/${data.id}`)
            : undefined
        }
      />
    </div>
  );
}

export default EmployeeCard;
