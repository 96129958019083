/** @format */

import { Modal, Table, TableProps } from "antd";
import clsx from "clsx";
import dayjs from "dayjs";
import { useState } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import { TExportTickets } from "../../../types/products";
import style from "./style.module.scss";
const mapStatus: Record<string, Record<string, string>> = {
  ar: {
    pending: "جاري العمل",
    new: "جديد",
    closed: "مغلق",
  },
  en: {
    pending: "Pending",
    new: "New",
    closed: "Closed",
  },
};
const mapStatusToStyle: Record<string, string> = {
  pending: style.pending,
};
export default function ExportTickets({ data }: { data: TExportTickets[] }) {
  const [showTicket, toggleTicketReplay] = useState<boolean>(false);
  const [selectedTicket, setTicket] = useState<TExportTickets | undefined>();

  const openReplay = (record?: TExportTickets) => {
    setTicket(record);
    toggleTicketReplay(!showTicket);
  };
  const columns: TableProps<TExportTickets>["columns"] = [
    {
      title: () => <p className={style.productHeader}>تاريخ الإضافة</p>,
      dataIndex: "created_at",
      key: "created_at",
      render: (text: string) => (
        <p className={style.createdAt}>
          {dayjs(text).format("DD-MMMM-YYYY HH:mm a")}
        </p>
      ),
    },
    {
      title: "عنوان التذكرة",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "	رقم الطلب",
      key: "id",
      dataIndex: "id",
      render: (text: number) => <b className={style.ticketNum}>#{text}</b>,
    },
    {
      title: "الحالة",
      key: "status",
      dataIndex: "status",
      render: (text: string) => (
        <p className={clsx(style.status, mapStatusToStyle[text])}>
          {mapStatus.ar[text]}
        </p>
      ),
    },
    {
      title: "التعليق",
      key: "replies",
      dataIndex: "replies",
      render: (_, record) =>
        !!record.replies.length ? (
          <BsFillEyeFill
            onClick={() => openReplay(record)}
            className={style.eye}
          />
        ) : null,
    },
  ];
  return (
    <div>
      <Table<TExportTickets>
        className={style.balanceTable}
        columns={columns}
        pagination={false}
        dataSource={data}
        scroll={{ x: "max-content" }}
      />
      <Modal
        title='الرد على التذكرة'
        open={showTicket}
        cancelText='إغلاق'
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{
          style: { border: "none", backgroundColor: "#f3f6f9" },
        }}
        onCancel={() => toggleTicketReplay(false)}>
        <h3>عنوان الشكوى</h3>
        {selectedTicket?.replies.toString()}
      </Modal>
    </div>
  );
}
