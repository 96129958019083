/** @format */

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Input, Select } from "antd";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useGetAllOrdersIds } from "../../../hooks/order";
import { useAddTicket } from "../../../hooks/useTicket";

import ImageUploader from "../../Common/ImageUploader";
import style from "./style.module.scss";
const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  order_id: yup.number().required("Order id is required"),
  details: yup.string().required("Details is required"),
  attachments: yup.mixed(),
  // .test(
  //   "يجب أن يكون receipt image ملف من النوع: jpeg, png, jpg, pdf.",
  //   (value) => {
  //     return (
  //       value &&
  //       value instanceof File &&
  //       ["image/jpeg", "image/png", "image/jpg", "image/pdf"].includes(
  //         value.type
  //       )
  //     );
  //   }
  // ),
});
const { TextArea } = Input;

export default function AddTicketForm() {
  const navigate = useNavigate();
  const [attachments, setImage] = useState<string | null>(null);
  // const { data: tickets } = useGitTicket();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { data, mutate, isLoading } = useAddTicket(navigate);
  const { data: orderIds } = useGetAllOrdersIds();
  const onSubmit = async (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: any) => {
      formData.append(key, data[key]);
    });

    await mutate(formData);
  };
  const saveImage = (image: string | null, file: File) => {
    setValue("attachments", file);
    setImage(image);
  };
  return (
    <div className={style.cont}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.cashCont}>
          <div className={style.formCont}>
            <div className={style.form}>
              <div>
                <label>عنوان التذكرة</label>

                <Controller
                  name='title'
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder='اكتب عنوان يصف المشكلة بشكل دقيق '
                      size='large'
                      {...field}
                    />
                  )}
                />
                {errors.title?.message ? (
                  <span className={style.error}>{errors.title?.message}</span>
                ) : null}
              </div>
              <div>
                <label> رقم الطلب (اختياري)</label>

                <Controller
                  name='order_id'
                  control={control}
                  render={({ field }) => (
                    <Select
                      //   showSearch
                      size='large'
                      style={{ width: "100%" }}
                      options={[
                        {
                          label: <span>أدخل رقم الطلب</span>,
                          title: "manager",
                          options: orderIds?.map((ticket) => ({
                            label: `#${ticket}`,
                            value: ticket,
                          })),
                        },
                      ]}
                      {...field}
                      placeholder=' ادخل رقم الطلب'
                      showSearch
                    />
                  )}
                />
                {errors.order_id?.message ? (
                  <span className={style.error}>
                    {errors.order_id?.message}
                  </span>
                ) : null}
              </div>
              <div>
                <label>تفاصيل التذكرة</label>
                <Controller
                  name='details'
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      rows={4}
                      placeholder=' أضف تفاصيل التذكرة بشكل مفصل وإرفاق أي صور للأخطاء عند وجودها'
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className={style.receiptCont}>
            <p> تحميل المرفقات</p>
            <ImageUploader setImage={saveImage} image={attachments} />
            {/* {errors.attachments?.message ? (
              <span className={style.error}>{errors.attachments?.message}</span>
            ) : null} */}
          </div>
        </div>
        <Button
          loading={isLoading}
          variant='filled'
          htmlType='submit'
          color='default'>
          ارسال
        </Button>
      </form>
    </div>
  );
}
