/** @format */

import { TableProps, Tag } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEye, FaRegCopy } from "react-icons/fa";
import { LuDownload } from "react-icons/lu";
import { TbDownload } from "react-icons/tb";
import { exportToExcel } from "react-json-to-excel";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { orderStatus } from "../../../constant/orderStatus";
import { useGetOrder } from "../../../hooks/order";
import {
  TOrderDetailsProduct,
  TOrderProductOption,
} from "../../../types/order";
import CustomTable from "../../Common/CustomTable/CustomTable";
import ProductDetails from "../ProductDetails/ProductDetails";
import TopupModal from "../TopupModal/TopupModal";
import styles from "./OrderDetails.module.scss";

function OrderDetails() {
  const {
    i18n: { language },
  } = useTranslation();
  const { orderId } = useParams();
  const [topupOptionsModal, setTopupOptionsModal] = useState<{
    isOpen: boolean;
    data: TOrderProductOption[];
  }>({
    isOpen: false,
    data: [],
  });
  const { data: orderDetails, isLoading } = useGetOrder(orderId as string);
  const currencyCode = orderDetails?.order_details?.currency?.code;
  const columns: TableProps<TOrderDetailsProduct>["columns"] = useMemo(
    () => [
      {
        title: "تفاصيل المنتج",
        dataIndex: "product",
        key: "product",
        render: (_, record) => <ProductDetails product={record} />,
        minWidth: 400,
      },
      {
        title: "الحالة",
        dataIndex: "status",
        key: "status",
        render: (val) => (
          <Tag
            className={styles.tag}
            color={orderStatus[val as keyof typeof orderStatus]?.color}
          >
            {orderStatus[val as keyof typeof orderStatus]?.label}{" "}
          </Tag>
        ),
      },
      {
        title: "ملف المنتج / رقم المعرف ID	",
        dataIndex: "orderProductSerials",
        key: "orderProductSerials",

        render: (val, record) => {
          console.log({ orderProductSerials: record.orderProductSerials });

          if (record.status === "rejected") return <p>لا يوجد</p>;
          const isNotTopUp = record.type !== "topup";
          const canCopySerials = record.orderProductSerials?.length <= 5;
          const serialsObj: any = {};
          record?.orderProductSerials.forEach(
            (val, index) => (serialsObj[`serial-${index + 1}`] = val)
          );
          return (
            <div
              className={styles.downloadFile}
              onClick={() => {
                if (isNotTopUp) {
                  const serialsToCopy = record?.orderProductSerials?.join("-");
                  if (!canCopySerials) {
                    exportToExcel([serialsObj], `product-serials-${record.id}`);
                  } else {
                    navigator.clipboard.writeText(serialsToCopy);
                    toast.success("تم نسخ السيريلات بنجاح");
                  }
                } else {
                  setTopupOptionsModal({
                    isOpen: true,
                    data: record.options,
                  });
                }
              }}
            >
              {isNotTopUp ? (
                <>
                  {canCopySerials ? (
                    <FaRegCopy className={styles.icon} />
                  ) : (
                    <LuDownload className={styles.icon} />
                  )}
                </>
              ) : (
                <FaEye className={styles.icon} />
              )}
            </div>
          );
        },
      },
      {
        title: "السعر",
        dataIndex: "unit_price",
        key: "unit_price",
        render: (val, record) => <p>{`${val} ${currencyCode}`}</p>,
      },
      {
        title: "الكمية",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "الإجمالي",
        dataIndex: "total",
        key: "total",
        render: (val) => (
          <p>
            <b>{val}</b>
            &nbsp; {currencyCode}
          </p>
        ),
      },
    ],
    [language, currencyCode]
  );

  const hideShowInvoice = orderDetails?.order_details?.order_products?.some(
    (item) => item?.status === "completed"
  );
  return (
    <div className={styles.orderDetails}>
      <TopupModal
        isOpen={topupOptionsModal.isOpen}
        data={topupOptionsModal.data}
        reset={() => setTopupOptionsModal({ isOpen: false, data: [] })}
      />
      <CustomTable
        heading={<h4>تفاصيل الطلب</h4>}
        buttonProps={
          hideShowInvoice
            ? {
                btnValue: "الفاتورة",
                href: `/${language}/invoice/${orderId}`,
                className: styles.invoiceBtn,
                icon: <TbDownload />,
              }
            : undefined
        }
        loading={isLoading}
        columns={columns as any}
        dataSource={orderDetails?.order_details?.order_products}
        configStyle={{
          fontWeightStrong: 500,
          headerColor: "#878A99",
          padding: 10,
        }}
      />
      <hr className={styles.dashedLine} />
      <div className={styles.orderDetailsFooter}>
        <ul className={styles.invoice}>
          <li>
            <p>الإجمالي قبل الخصم والضريبة : </p>
            <p>
              {orderDetails?.order_details?.sub_total} {currencyCode}
            </p>
          </li>
          <li>
            <p>الضريبة: </p>
            <p>{`${orderDetails?.order_details?.tax} ${currencyCode}`}</p>
          </li>
          <li className={styles.dashedLine} />
          <li>
            <p>
              <b>الإجمالي : </b>
            </p>
            <p>
              <b>{orderDetails?.order_details?.total}</b> {currencyCode}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default OrderDetails;
