import { TOrderDetailsProduct } from "../../../types/order";
import styles from "./ProductDetails.module.scss";

type TProps = {
  product: TOrderDetailsProduct;
};
function ProductDetails({ product }: TProps) {
  return (
    <div className={styles.productDetails}>
      <div className={styles.imgBox}>
        <img
          src={product?.product?.image}
          alt={product?.product?.product?.meta_description}
        />
      </div>
      <div className={styles.details}>
        <h4> {product?.product?.name || "غير معروف"} </h4>
        <p>
          العلامة التجارية: <span>{product?.product?.brand?.name}</span>
        </p>
      </div>
    </div>
  );
}

export default ProductDetails;
