import { TableProps } from "antd";
import { useMemo } from "react";
import CustomTable from "../../Common/CustomTable/CustomTable";
import styles from "./BrandSalesTable.module.scss";

function BrandSalesTable() {
  const columns: TableProps<any>["columns"] = useMemo(
    () => [
      {
        title: " العلامة التجارية		",
        dataIndex: "name",
        key: "name",
        minWidth: 300,
        render: (_, record) => <b>#VZ2101</b>,
      },
      {
        title: " الكمية المباعة	",
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: " تكلفة الكمية المباعة		",
        dataIndex: "cardsNumber",
        key: "cardsNumber",
      },
      {
        title: "إجمالي المبيعات",
        dataIndex: "total",
        key: "total",
        render: (_, record) => (
          <p>
            <b>1236.00</b>
          </p>
        ),
      },
    ],
    []
  );
  return (
    <div className={styles.brandSalesTable}>
      <CustomTable
        className={styles.customTable}
        columns={columns}
        configStyle={{
          fontWeightStrong: 500,
          headerColor: "#878A99",
          padding: 10,
        }}
      />
    </div>
  );
}

export default BrandSalesTable;
