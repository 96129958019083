/** @format */

import React from "react";
import Box from "../Common/Box";
import Cart from "./Cart";
import style from "./style.module.scss";

export default function StorePage({
  title,
  children,
}: {
  title?: string;
  children: React.ReactElement;
}) {
  return (
    <div className={style.storeCont}>
      <Box title={title || "المتجر"}>{children}</Box>
      <Box title='سلة الشراء'>
        <Cart />
      </Box>
    </div>
  );
}
