import { TableProps, Tag } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { orderStatus } from "../../../constant/orderStatus";
import { useGetAllOrders } from "../../../hooks/order";
import useAuthorization from "../../../hooks/useAuthorization";
import { TOrder } from "../../../types/order";
import formateDate from "../../../utils/formateDate.";
import CustomLink from "../../Common/CustomLink/CustomLink";
import CustomTable from "../../Common/CustomTable/CustomTable";
import RowActions from "../../Common/TableComponents/RowActions/RowActions";
import styles from "./ListOrders.module.scss";

function ListOrders() {
  const {
    i18n: { language },
  } = useTranslation();
  const [params, setParams] = useState({ page: 1 });
  const {
    data: orders,
    isLoading: ordersLoading,
    isFetching,
    isSuccess,
    refetch: refetchOrders,
  } = useGetAllOrders(params);
  const navigate = useNavigate();
  const { hasPermission } = useAuthorization();

  useEffect(() => {
    refetchOrders();
  }, [params]);

  useEffect(() => {
    if (isFetching && isSuccess) {
      setParams((prev) => ({ ...prev, page: orders.current_page }));
    }
  }, [isFetching, isSuccess]);

  const columns: TableProps<TOrder>["columns"] = useMemo(
    () => [
      {
        title: "رقم الطلب",
        dataIndex: "id",
        key: "id",
        render: (_, record) => (
          <CustomLink to={`/orders/${record.id}`} className={styles.link}>
            <b>{record?.id}#</b>
          </CustomLink>
        ),
      },
      {
        title: "تاريخ الطلب	",
        dataIndex: "created_at",
        key: "created_at",
        render: (_, record) => <p>{formateDate(record?.created_at)}</p>,
      },
      {
        title: "عدد البطاقات	",
        dataIndex: "order_products_count",
        key: "order_products_count",
      },
      {
        title: "القيمة	",
        dataIndex: "total",
        key: "total",
        render: (val, record) => (
          <p>
            <b>{Number(val)?.toFixed(2)}</b>
            &nbsp;
          </p>
        ),
      },
      {
        title: "الحالة	",
        dataIndex: "status",
        key: "status",
        render: (val) => (
          <Tag
            className={styles.tag}
            color={orderStatus[val as keyof typeof orderStatus]?.color}
          >
            {orderStatus[val as keyof typeof orderStatus]?.label}{" "}
          </Tag>
        ),
      },
      {
        title: "بواسطة	",
        dataIndex: "user",
        key: "user",
        render: (_, record) => <strong>{record.owner?.name}</strong>,
      },
      {
        title: "",
        dataIndex: "id",
        key: "createdBy",
        render: (_, record) => {
          if (hasPermission("ViewOrder")) {
            return (
              <RowActions
                onPreview={() => navigate(`/${language}/orders/${record?.id}`)}
              />
            );
          }
        },
      },
    ],
    [language, isFetching]
  );

  return (
    <div className={styles.listOrders}>
      <CustomTable
        loading={ordersLoading}
        heading={<h4>طلباتي</h4>}
        buttonProps={
          hasPermission("AddOrder")
            ? {
                btnValue: "إنشاء طلب",
                href: `/${language}/store`,
              }
            : undefined
        }
        columns={columns as any}
        dataSource={orders?.data}
        configStyle={{
          fontWeightStrong: 500,
          headerColor: "#878A99",
          padding: 10,
        }}
        pagination={{
          current: orders?.current_page,
          total: orders?.total,
          pageSize: orders?.per_page,
          onChange: (page) => {
            setParams((prev) => ({ ...prev, page }));
          },
        }}
      />
    </div>
  );
}

export default ListOrders;
