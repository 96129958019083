/** @format */
import { Dropdown, MenuProps } from "antd";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { BsFullscreenExit } from "react-icons/bs";
import { IoTimeOutline } from "react-icons/io5";

import { CgMenuRight, CgProfile } from "react-icons/cg";
import { FaRegBell } from "react-icons/fa";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { IoIosLogOut } from "react-icons/io";
import { RiFullscreenLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../../../hooks/auth";
import useFullScreen from "../../../hooks/useFullScreen";
import { TUser } from "../../../types/auth";
import styles from "./Navbar.module.scss";

import {
  useChangeCurrency,
  useGetAllCurrencies,
  useGetAllNotification,
  useGetAllNotificationCount,
} from "../../../hooks/lookups";
import { TNotification } from "../../../types/TLookups";
import formateDate from "../../../utils/formateDate.";
import "./_antd.scss";

type TProps = {
  setSideNavExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  isExpanded: boolean;
  user?: TUser;
};

function Navbar({ setSideNavExpanded, isExpanded, user }: TProps) {
  const navigate = useNavigate();

  const [isFullScreen, onToggleFullScreen] = useFullScreen();
  const { mutate: logout, isLoading } = useLogout(navigate);
  const { i18n } = useTranslation();
  const { data: currencies, isLoading: isLoadingCurrencies } =
    useGetAllCurrencies();
  const { data: notification } = useGetAllNotification();
  // const { data } = useReadAllNotification();
  const { data: notificationCount } = useGetAllNotificationCount();
  const { mutate: changeCurrency, isLoading: isLoadingChangeCurrency } =
    useChangeCurrency();

  const changeLanguage = (lang?: string) => {
    i18n.changeLanguage(lang);
    document.documentElement.dir = i18n.dir();
    document.documentElement.lang = i18n.language;
  };

  const onToggleMenu = () => {
    setSideNavExpanded((prev) => {
      localStorage.setItem("sideNavExpanded", String(!prev));
      return !prev;
    });
  };

  const languageMenu: MenuProps["items"] = [
    {
      key: "en-lang",
      label: "English",
      onClick: () => changeLanguage("en"),
      icon: (
        <div className={clsx(styles.languages)}>
          <img alt='language' src='/assets/icons/us-flag.svg' />
        </div>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "ar-lang",
      label: "اللغة العربية",
      onClick: () => changeLanguage("ar"),
      icon: (
        <div className={clsx(styles.languages)}>
          <img alt='language' src='/assets/icons/ae-flag.svg' />
        </div>
      ),
    },
  ];
  const notificationMenu: MenuProps["items"] = notification?.data.length
    ? [
        {
          key: 0,
          label: (
            <div>
              <p>الاشعارات</p> <p></p>
            </div>
          ),
        },
        ...notification?.data.map((item: TNotification) => ({
          key: item.id,
          label: (
            <div className={styles.navItem}>
              <h3>{item.data.details.title}</h3> <p>{item.data.details.body}</p>{" "}
            </div>
          ),
        })),
      ]
    : [{ label: <h3> لا يوجد اشعارات</h3>, key: 0 }];
  const profileMenu: MenuProps["items"] = [
    {
      key: "profile",
      label: "الملف الشخصي",
      icon: <CgProfile className={styles.icon} />,
      onClick: () => navigate(`/${i18n.language}/settings/profile/view`),
    },
    {
      type: "divider",
    },
    {
      key: "logout",
      label: isLoading ? "جاري تسجيل الخروج" : "تسجيل الخروج",
      icon: <IoIosLogOut className={styles.icon} />,
      onClick: () => logout(),
    },
  ];

  const currenciesMenu: MenuProps["items"] = currencies?.map((currency) => ({
    key: currency?.id,
    label: currency?.name || "unknown",
    onClick: () => changeCurrency(currency?.id),
    disabled: user?.currency?.id === currency?.id,
  }));

  return (
    <nav className={styles.navbar}>
      <div className={clsx(styles.searchbar, "searchInput")}>
        {user?.approval_status === "approved" && (
          <div className={styles.sidebarToggle} onClick={onToggleMenu}>
            {isExpanded ? <CgMenuRight /> : <HiOutlineArrowNarrowLeft />}
          </div>
        )}
        {/* 
        {showSearch && (
          <div className={clsx(styles.searchInput)}>
            {isMobile ? (
              <Input.Search
                placeholder="بحث"
                allowClear
                size="large"
                onSearch={console.log}
                enterButton
                height={30}
              />
            ) : (
              <Input
                className={clsx(styles.searchInput)}
                prefix={<LuSearch />}
                placeholder="بحث"
              />
            )}
          </div>
        )} */}
      </div>
      <div className={styles.settings}>
        {/* <div
          onClick={() => setShowSearch((prev) => !prev)}
          className={clsx(
            styles.settingItem,
            styles.search,
            showSearch && styles.settingItemActive
          )}
        >
          <LuSearch />
        </div> */}
        <Dropdown
          trigger={["click"]}
          menu={{
            items: languageMenu,
          }}>
          <div className={clsx(styles.settingItem, styles.languages)}>
            <img
              alt='language'
              src={
                i18n.language === "ar"
                  ? "/assets/icons/ae-flag.svg"
                  : "/assets/icons/us-flag.svg"
              }
            />
          </div>
        </Dropdown>
        {/* <div className={styles.shoppingCart}>
          <div
            onClick={() => setShowShoppingCart(true)}
            className={clsx(styles.settingItem)}
          >
            <BiShoppingBag />
            <span className={styles.badge}>4</span>
          </div>
          <ShoppingCartModal
            isOpen={showShoppingCart}
            onClose={() => setShowShoppingCart(false)}
          />
        </div> */}
        <Dropdown
          trigger={["click"]}
          menu={{
            items: currenciesMenu,
          }}>
          <div className={clsx(styles.settingItem)}>
            {/* <MdCurrencyExchange /> */} {user?.currency.code}
          </div>
        </Dropdown>

        <div
          onClick={() => onToggleFullScreen()}
          className={clsx(styles.settingItem, styles.fullScreen)}>
          {isFullScreen ? <BsFullscreenExit /> : <RiFullscreenLine />}
        </div>
        <Dropdown
          className={styles.notificationDrop}
          trigger={["click"]}
          menu={{
            items: notificationMenu,
          }}
          dropdownRender={() => (
            <div className={styles.notificationDrop}>
              {notification?.data.length ? (
                <>
                  <div className={styles.header}>
                    <p>الاشعارات</p>{" "}
                    <p className={styles.count}>{notificationCount} جديد</p>
                  </div>
                  {notification?.data.map((item: TNotification) => (
                    <div className={styles.navItem} key={item.id}>
                      <div>
                        <h6>{item.data.details.title}</h6>
                        <span>
                          <IoTimeOutline /> {formateDate(item.created_at)}{" "}
                        </span>
                      </div>
                      {item.read_at ? null : <p />}

                      {/* <p>{item.data.details.body}</p>{" "} */}
                    </div>
                  ))}
                </>
              ) : (
                <h3> لا يوجد اشعارات</h3>
              )}
            </div>
          )}>
          <div className={clsx(styles.settingItem, styles.notification)}>
            <FaRegBell />
            {notificationCount ? (
              <span className={styles.badge}>{notificationCount}</span>
            ) : null}
          </div>
        </Dropdown>
        <Dropdown
          trigger={["click"]}
          menu={{
            items: profileMenu,
          }}>
          <div className={clsx(styles.profile)}>
            <div className={clsx(styles.profileImg)}>
              <img alt='avatar' src={user?.logo} />
            </div>
            <div className={clsx(styles.userInfo)}>
              <h3 className={clsx(styles.name)}>{user?.name}</h3>
              <p className={clsx(styles.desc)}>{user?.owner_name}</p>
            </div>
          </div>
        </Dropdown>
      </div>
    </nav>
  );
}

export default Navbar;
