import { useParams } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import { useGetOrder } from "../../hooks/order";
import CustomSkelton from "../CustomSkelton/CustomSkelaton";
import InvoiceClient from "./InvoiceClient/InvoiceClient";
import InvoiceFooter from "./InvoiceFooter/InvoiceFooter";
import InvoiceHeader from "./InvoiceHeader/InvoiceHeader";
import ProductTable from "./ProductTable/ProductTable";
import styles from "./index.module.scss";

function InvoiceDetail() {
  const { orderId } = useParams();

  const { data: orderDetails, isLoading: orderDetailsLoader } = useGetOrder(
    orderId as string
  );
  const { toPDF, targetRef } = usePDF({
    filename: `invoice-order-${orderId}.pdf`,
  });

  if (orderDetailsLoader) return <CustomSkelton />;
  return (
    <div ref={targetRef} className={styles.invoiceDetail}>
      <InvoiceHeader data={orderDetails} />
      <InvoiceClient data={orderDetails} />
      <ProductTable data={orderDetails} />
      <InvoiceFooter toPDF={toPDF} />
    </div>
  );
}

export default InvoiceDetail;
