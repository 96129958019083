import Logo from "../../../assets/images/logo-onLight.png";
import { useGetAllSettings } from "../../../hooks/lookups";
import { TOrderDetails } from "../../../types/order";
import formateDate from "../../../utils/formateDate.";
import styles from "./InvoiceHeader.module.scss";

type TProps = {
  data?: TOrderDetails;
};
function InvoiceHeader({ data }: TProps) {
  const { data: settings } = useGetAllSettings();
  const postalCode = settings?.settings.find(
    (item) => item.key === "postal_code"
  );
  const address = settings?.settings.find(
    (item) => item.key === "address_line"
  );
  const city = settings?.settings.find((item) => item.key === "city_id");
  return (
    <div className={styles.invoiceHeader}>
      <div className={styles.header}>
        <div className={styles.logoBox}>
          <img src={Logo} alt="logo" />
        </div>
        <ul className={styles.addressDetails}>
          <li>
            <p>تيك كارد </p>
            <p> Digital Cards</p>
          </li>
          <li>
            <p> {address?.translations?.[0]?.value}</p>
            <p> {address?.translations?.[1]?.value}</p>
          </li>
          <li>
            <p> صندوق بريد {postalCode?.plain_value} </p>
            <p> P.O.Box {postalCode?.plain_value}</p>
          </li>
          <li>
            <p>{city?.translations?.[0]?.value}</p>
            <p>{city?.translations?.[1]?.value}</p>
          </li>
        </ul>
      </div>
      <ul className={styles.orderDetails}>
        {data?.setting_tax.show_tax_number ? (
          <li>
            <p>
              <span>الرقم الضريبي</span>&nbsp;
              <span>VAT No.</span>
            </p>
            <p>{data?.setting_tax.tax_number}</p>
          </li>
        ) : null}
        <li className={styles.divider} />

        <li>
          <p>
            <span> رقم الفاتورة</span>&nbsp;
            <span>Invoice No.</span>
          </p>
          <p>{data?.order_details?.id}</p>
        </li>
        <li className={styles.divider} />

        <li>
          <p>
            <span> تاريخ الفاتورة</span>&nbsp;
            <span>Invoice Date</span>
          </p>
          <p className={styles.invoiceDate}>
            <span>{formateDate(data?.order_details?.created_at)}</span>&nbsp;
          </p>
        </li>
        <li className={styles.divider} />

        <li>
          <p>
            <span> عملة الفاتورة</span>&nbsp;
            <span> Invoice Currency</span>
          </p>
          <p>
            <span>{data?.order_details?.currency?.name}</span> &nbsp;
            <span>({data?.order_details?.currency?.code})</span>
          </p>
        </li>
      </ul>
    </div>
  );
}

export default InvoiceHeader;
