/** @format */

import { useMemo } from "react";
import { Permissions } from "../services/permissions";
import { useGetProfile } from "./profile";
export default function useAuthorization() {
  const { data, ...query } = useGetProfile();

  const loginUserPermissions = useMemo(() => {
    if (query.isSuccess && data?.permissions) {
      return data.permissions.map((permission) => permission.name);
    }
    return [];
  }, [data?.permissions, query.isSuccess]);

  const hasPermission = (permissionName: keyof typeof Permissions) => {
    return loginUserPermissions?.includes(
      Permissions[permissionName] as string
    );
  };

  return { hasPermission, ...query };
}
