import { Modal, TableProps } from "antd";
import { useMemo } from "react";
import { FaRegCopy } from "react-icons/fa";
import { TOrderProductOption } from "../../../types/order";
import CustomTable from "../../Common/CustomTable/CustomTable";

import { toast } from "react-toastify";
import styles from "./TopupModal.module.scss";
type TProps = {
  isOpen: boolean;
  data: TOrderProductOption[];
  reset: () => void;
};
function TopupModal({ isOpen, data, reset }: TProps) {
  const handleCopyToClip = (val: string) => {
    navigator.clipboard
      .writeText(val)
      .then(() => {
        toast.success("تم نسخ الخيارات بنجاح");
      })
      .catch((err) => {
        toast.error("حدث خطأ ما");
      });
  };

  const columns: TableProps<TOrderProductOption>["columns"] = useMemo(
    () => [
      {
        title: "السيريلات",
        dataIndex: "name",
        key: "name",
        render: (_, record) => <p>{record?.option_details.name}</p>,
      },
      {
        title: "الخدش",
        dataIndex: "product",
        key: "product",
        render: (_, record) => {
          const value =
            record?.value ||
            record.option_values
              .map((val) => val?.option_value_details?.name)
              .join(", ");
          return (
            <p
              className={styles.optionValue}
              onClick={() => handleCopyToClip(value)}
            >
              {value}
              <FaRegCopy className={styles.copy} />
            </p>
          );
        },
      },
    ],
    [data]
  );
  return (
    <Modal
      title="خيارات المنتج"
      open={isOpen}
      cancelText="إغلاق"
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{
        style: { border: "none", backgroundColor: "#f3f6f9" },
      }}
      onCancel={reset}
    >
      <CustomTable
        className={styles.table}
        dataSource={data}
        columns={columns as any}
      />
    </Modal>
  );
}

export default TopupModal;
