/** @format */
import { Player } from "@lordicon/react";
import { useEffect, useRef } from "react";
import Employee from "../../../assets/lottie/addEmployee.json";
import AddNote from "../../../assets/lottie/addNote.json";
import Coin from "../../../assets/lottie/coin.json";
import Cart from "../../../assets/lottie/dashboardCart.json";
import Fav from "../../../assets/lottie/fav.json";
import CustomLink from "../../Common/CustomLink/CustomLink";
import style from "./style.module.scss";
const cards = [
  { label: "شحن المحفظة", icon: Coin, route: "/charge-wallet" },
  { label: "إنشاء طلب", icon: Cart, route: "/store" },
  { label: "المفضلة", icon: Fav, route: "/fav" },
  { label: "إضافة موظف", icon: Employee, route: "/settings/Employees/add" },
  { label: "إضافة تذكرة", icon: AddNote, route: "/support/add-ticket" },
];
export default function DashboardRoutes() {
  return (
    <div className={style.routesCont}>
      {cards.map((card, index) => (
        <Card card={card} key={card.label} />
      ))}
    </div>
  );
}
const Card = ({ card }: { card: any }) => {
  const playerRef1 = useRef<Player>(null);

  useEffect(() => {
    playerRef1.current?.playFromBeginning();
  }, []);
  return (
    <CustomLink to={card.route}>
      <div className={style.card}>
        <Player
          onComplete={() => playerRef1.current?.playFromBeginning()}
          size={32}
          ref={playerRef1}
          icon={card.icon}
          colors='primary:#6691e7,secondary:#fb8b02'
        />
        <p>{card.label}</p>
      </div>
    </CustomLink>
  );
};
