/** @format */

import type {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { TErrorResponse } from "../types/api";

type TRequestConfig = {
  url: string;
  method: string;
};

const api: AxiosInstance = axios.create({
  // baseURL: "https://takecard-api.multi-choice.com/api/seller",
  baseURL: "https://pins-api.tagrebty.net/api/seller",
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${Cookies.get("token")}`,
    "Accept-Language": Cookies.get("i18next") || "ar",
  },
});

const onReject = async (error: AxiosError<TErrorResponse>) => {
  try {
    if (error.response?.data) {
      const { errors, message } = error.response?.data;

      if (message) {
        toast.error(message, { toastId: message });
      } else {
        if (typeof errors === "string") {
          toast.error(errors, { toastId: errors });
        } else {
          for (const key in errors) {
            toast.error(errors[key]?.[0], { toastId: errors[key]?.[0] });
          }
        }
      }
    }
  } catch (err) {
    console.error(err);
  }
  return Promise.reject(error);
};

const onRequest = async (
  config: InternalAxiosRequestConfig<TRequestConfig>
) => {
  config.headers.Authorization = `Bearer ${Cookies.get("token")}`;
  return config;
};

const onResponse = (response: AxiosResponse) => {
  return response;
};

api.interceptors.request.use(onRequest, onReject);
api.interceptors.response.use(onResponse, onReject);

export default api;
