import { useState } from "react";
import BrandSalesTable from "./BrandSalesTable/BrandSalesTable";
import DepartmentalSalesTable from "./DepartmentalSalesTable/DepartmentalSalesTable";
import styles from "./index.module.scss";
import ProductSalesTable from "./ProductSalesTable/ProductSalesTable";
import ReportsActions from "./ReportsActions/ReportsActions";
import ReportsTab from "./ReportsTab/ReportsTab";
import SalesOverTimePeriodsTable from "./SalesOverTimePeriodsTable/SalesOverTimePeriodsTable";
import StaffSalesTable from "./StaffSalesTable/StaffSalesTable";

function ReportsContent() {
  const [activeTab, setActiveTab] = useState("products");
  return (
    <div className={styles.reportsContent}>
      <ReportsTab active={activeTab} setActive={setActiveTab} />
      <div className={styles.reportsTable}>
        <ReportsActions activeTab={activeTab} />
        {activeTab === "products" && <ProductSalesTable />}
        {activeTab === "time" && <SalesOverTimePeriodsTable />}
        {activeTab === "brand" && <BrandSalesTable />}
        {activeTab === "depart" && <DepartmentalSalesTable />}
        {activeTab === "employee" && <StaffSalesTable />}
      </div>
    </div>
  );
}

export default ReportsContent;
