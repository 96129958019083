/** @format */

import { TableProps } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useChangeRoleStatus,
  useDeleteRole,
  useGetRoles,
} from "../../../hooks/roles";
import useAuthorization from "../../../hooks/useAuthorization";
import { TRole } from "../../../types/roles";
import CustomSwitcher from "../../Common/CustomSwitcher/CustomSwitcher";
import CustomTable from "../../Common/CustomTable/CustomTable";
import SectionActions from "../../Common/SectionActions/SectionActions";
import RowActions from "../../Common/TableComponents/RowActions/RowActions";
import CustomSkelton from "../../CustomSkelton/CustomSkelaton";
import styles from "./ListRoles.module.scss";

function ListRoles() {
  const [search, setSearch] = useState<string>("");
  const { data, isLoading, refetch } = useGetRoles(search);
  const { mutate: changeRoleStatus, isLoading: changeRoleStatusLoader } =
    useChangeRoleStatus();
  const [updatingRecord, setUpdatingRecord] = useState<number>();
  const navigate = useNavigate();
  const { mutate: deleteRole, isLoading: deleteRoleLoader } = useDeleteRole();
  const { hasPermission } = useAuthorization();

  useEffect(() => {
    refetch();
  }, [search]);

  const columns: TableProps<TRole>["columns"] = [
    {
      title: "اسم الدور ",
      dataIndex: "display_name",
      key: "display_name",
      render: (val: string) => <h4>{val}</h4>,
      minWidth: 350,
    },
    {
      title: "الحالة",
      dataIndex: "status",
      key: "status",
      render: (val, record) => {
        if (hasPermission("SettingChangeStatusRole"))
          return (
            <CustomSwitcher
              loading={changeRoleStatusLoader && updatingRecord === record.id}
              onChange={(val) => {
                setUpdatingRecord(record.id);
                changeRoleStatus({
                  id: record.id,
                  status: val ? "active" : "inactive",
                });
              }}
              checked={val === "active"}
            />
          );
      },
    },
    {
      title: "الإجراء",
      dataIndex: "id",
      key: "id",
      render: (val) => (
        <RowActions
          onDelete={
            hasPermission("SettingDeleteRole")
              ? () => deleteRole(val)
              : undefined
          }
          isLoading={deleteRoleLoader}
          onEdit={
            hasPermission("SettingEditRole")
              ? () => navigate(`/settings/employees/edit-role/${val}`)
              : undefined
          }
        />
      ),
    },
  ];
  document.title = "الادوار";
  if (isLoading) {
    return <CustomSkelton />;
  }

  return (
    <div className={styles.listRoles}>
      <SectionActions
        search={{
          onChange: (val) => setSearch(val),
          placeholder: "ابحث عن مجموعة  ......",
        }}
        button={
          hasPermission("SettingAddRole")
            ? {
                href: "/settings/Employees/add-role",
                value: " أضف دور جديدة ",
              }
            : undefined
        }
      />
      <CustomTable
        columns={columns as any}
        dataSource={data}
        loading={isLoading}
      />
    </div>
  );
}

export default ListRoles;
